/* CSS Reset */

html, body, div, span, applet, object, iframe,  
h1, h2, h3, h4, h5, h6, p, blockquote, pre,  
a, abbr, acronym, address, big, cite, code,  
del, dfn, em, img, ins, kbd, q, s, samp,  
small, strike, strong, sub, sup, tt, var,  
b, u, i, center,  
dl, dt, dd, ol, ul, li,  
fieldset, form, label, legend,  
table, caption, tbody, tfoot, thead, tr, th, td,  
article, aside, canvas, details, embed,  
figure, figcaption, footer, header, hgroup,  
menu, nav, output, ruby, section, summary,  
time, mark, audio, video {  
  margin: 0;  
  padding: 0;  
  border: 0;  
  font-size: 100%;  
  font: inherit;  
  vertical-align: baseline;  
}

/* Add page font family, size, and color for sections */

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Cardo', sans-serif;
  display: flex;
  justify-content: none;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #333;
  width: 100%;
}

#root {
  width: inherit;
}

.top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 44px;
  background-color: #632F43;
}

.nav-menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex: 1;
  font-size: 1.5rem;
}

.nav-link {
  text-decoration: none;
  color:#fff;
}

.main-section {
  width: inherit;
  border: solid transparent;
  padding: 0.5%;
  border-image-source: url('./assets/wedding_invitation.png');
  border-image-repeat: round;
  border-image-width: 3vw;
  border-image-slice: 40;
}

h1 {
  font-size: 5rem;
  text-align: center;
  margin: 5% 0 0 0;
}

.welcome-text{
  font-size: 1.75rem;
  margin: 1% 15% 0 15%;
}

.image-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 500px;
  overflow:auto;
}

figcaption {
  text-align: center;
  font-size: 1.5rem;
}

img {
  width: 400px;
}

h2 {
  font-size: 3.75rem;
  text-align: center;
  margin: 5% 0 0 0;
}

.rsvp-main-container {
  width: 100%;
}

form {
  margin: 3% 30% 5% 30%;
}

fieldset {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* align-items: center; */
}

.form-label {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  margin-top: 2.5%;
  padding: 2% 0;
}

input, select {
    /* To make sure that all text fields have the same font settings */
    font: 1.25rem sans-serif;

    /* Uniform text field size */
    padding: 1rem;
    box-sizing: border-box;

    /* Match form field borders */
    border: 1px solid #999;
    width: 21rem;
}

#big-input {
  height: 5rem;
}

#submitBtn {
  height: 3.5rem;
  font-size: 1.5rem;
  background-color: #632f43;
  color: white;
  border: solid;
  border-color: #632F43;
  border-radius: 0.5rem;
  margin: 5% 0 0 0;
}

#cancelBtn {
  height: 3.5rem;
  font-size: 1.5rem;
  color: #632F43;
  border: solid;
  border-color: #632F43;
  border-radius: 0.5rem;
  margin: 0 0 5% 0;
}

.button-div {
  text-align: center;
}

.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15%;
  font: black;
}

.info-section h2 {
  margin: 7.5% 0 5% 0;
}

.info-caps, .info, .hotel-contact-info {
  font-size: 1.75rem;
}

.info-caps {
  text-transform: uppercase;
}

.info {
  margin-bottom: 3.5%;
}

.hotel-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#hotel-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#hotel-info {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}

.info-section h3 {
  margin: 0 0 5% 0;
}

a {
  text-decoration: none;
  width: inherit;
  color: black;
}

h3 {
  font-size: 2.5rem;
}

.about-main-container {
  padding: 5% 0;
  margin-bottom: 30%;
}

.gifts-main-container {
  padding: 2.5% 0;
  margin-bottom: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift-request {
  margin: 2.5% 15% 5% 15%;
  font-size: 1.75rem;
}

@media (max-width: 500px) {

  form {
    margin: 3% 0 5% 0;
  }

  .gift-request {
    margin: 5% 10% 5% 10%;
    font-size: 1.75rem;
  }

  .info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5%;
    font: black;
  }

  #hotel-links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .info {
    margin-bottom: 5%;
  }

}